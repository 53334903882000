<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import SecondaryButton from "@/components/SecondaryButton.vue";
import MainForm from "@/components/MainForm.vue";
import FormTitle from "@/components/FormTitle.vue";

export default {
	components: {
		SecondaryButton,
		MainForm,
		FormTitle,
	},
	data() {
		return {
			showForm: false,
			listed: false,
			action: "Agregar",
			territories: [],
			territory: {
				id: { name: "Id", value: null },
				name: { name: "Nombre", value: null },
			},
		};
	},
	created() {
		this.loadTerritories();
	},
	computed: {
		...mapGetters({
			isLoading: "loading/isLoading",
			//READ_TERRITORIES
			readTerritories: "getTerritories/readTerritories",
			//READ_ERRORS
			getTerritoriesError: "getTerritories/readError",
			createError: "createTerritory/readError",
			updateError: "updateTerritory/readError",
			deleteError: "deleteTerritory/readError",
		}),
	},
	methods: {
		...mapMutations({
			setLoading: "loading/SET_LOADING",
			//SET_TERRITORIES
			setCreateTerritory: "createTerritory/SET_TERRITORY",
			setUpdateTerritory: "updateTerritory/SET_TERRITORY",
			setUpdateId: "updateTerritory/SET_ID",
			setDeleteId: "deleteTerritory/SET_ID",
			setTerritoryId: "getTerritories/SET_ID",

			//SET_ERRORS
			setGetError: "getTerritories/SET_ERROR",
			setCreateError: "createTerritory/SET_ERROR",
			setUpdateError: "updateTerritory/SET_ERROR",
			setDeleteError: "deleteTerritory/SET_ERROR",
		}),
		...mapActions({
			getTerritories: "getTerritories/getTerritories",
			createTerritory: "createTerritory/createTerritory",
			updateTerritory: "updateTerritory/updateTerritory",
			deleteTerritory: "deleteTerritory/deleteTerritory",
		}),
		getImage(image) {
			return `${require(`@/assets/images/views${this.$route.path.toUpperCase()}/${image}`)}`;
		},
		loadTerritories() {
			this.listed = false;
			this.clearErrors();
			this.territories = [];
			this.setTerritoryId(null);
			this.getTerritories().then((result) => {
				if (result) {
					if (this.readTerritories.length > 0) {
						this.territories = this.readTerritories;
						this.listed = true;
					}
				}
			});
		},
		toggleForm() {
			this.clearErrors();
			this.showForm = !this.showForm;
		},
		update(data) {
			this.clearErrors();
			this.toggleForm();
			this.action = "Actualizar";
			for (let r in this.territory) {
				this.territory[r]["value"] = data[r];
			}
		},
		remove(territory) {
			this.clearErrors();
			this.setLoading(true);

			this.$swal({
				title: "¿Desea eliminar este Territorio?",
				icon: "warning",
				showCancelButton: true,
				cancelButtonText: "Cancelar",
				confirmButtonText: "Aceptar",
			}).then((result) => {
				if (result.isConfirmed) {
					this.setDeleteId(territory.id.value || territory.id);
					this.deleteTerritory().then((result) => {
						if (result) {
							this.loadTerritories();
							this.$swal({
								icon: "success",
								title: `El Territorio ${
									territory.name.value || territory.name
								} ha sido eliminado correctamente.`,
								showConfirmButton: false,
								timer: 3000,
							});
							this.clear();
							if (this.showForm) this.toggleForm();
						}
					});
				}
			});
			this.setLoading(false);
		},
		submit() {
			this.clearErrors();
			this.setLoading(true);
			let territory = this.territory;
			let output = {};

			for (let r in territory) {
				output[r] = territory[r].value;
			}

			if (this.action == "Agregar") {
				this.setCreateTerritory(output);
				this.createTerritory().then((result) => {
					if (result) {
						this.loadTerritories();
						this.$swal({
							icon: "success",
							title: `El Territorio ${output.name} ha sido creado con éxito.`,
							showConfirmButton: false,
							timer: 3000,
						});
						this.clear();
						this.toggleForm();
					}
				});
			} else {
				this.setUpdateId(output.id);
				this.setUpdateTerritory(output);

				this.updateTerritory().then((result) => {
					if (result) {
						this.loadTerritories();
						this.$swal({
							icon: "success",
							title: `El Territorio ${output.name} se actualizó con éxito.`,
							showConfirmButton: false,
							timer: 3000,
						});
						this.clear();
						this.toggleForm();
					}
				});
			}
			this.setLoading(false);
		},
		clear() {
			for (let t in this.territory) {
				this.territory[t].value = null;
			}
			this.action = "Agregar";
		},
		clearErrors() {
			this.setGetError(null);
			this.setCreateError(null);
			this.setDeleteError(null);
			this.setUpdateError(null);
		},
	},
};
</script>

<template>
	<div class="main">
		<div
			v-if="showForm"
			class="modal-form fixed right-0 top-0 p-3 md:p-5 w-full h-full z-30 overflow-y-auto"
		>
			<div
				class="content-wrap bg-white border-2 border-gray px-6 pt-10 pb-6 shadow-md absolute max-w-3xl mx-auto right-0 left-0"
			>
				<SecondaryButton
					@click.native="[toggleForm(), clear()]"
					text="x"
					buttonColor="bg-red"
					class="close rounded-full h-10 absolute right-6 top-6 flex-none"
				/>
				<form-title
					:action="action"
					text="Territorio"
					textBold="Red Única"
					:name="territory.name.value"
				></form-title>
				<!-- DISPLAY FORM -->
				<form class="grid grid-cols-2 gap-5 items-center">
					<label
						class="col-span-2 text-lg"
						:for="key"
						v-for="(t, key, index) in territory"
						:key="key"
						v-show="key != 'id'"
						ref="labels"
					>
						<textarea
							:ref="index"
							:required="key != 'id'"
							class="w-full px-4 py-2 text-lg bg-gray-light rounded-md"
							type="text"
							:name="key"
							:placeholder="t.name"
							v-model="t.value"
							rows="2"
							v-capitalizeInput
						/>
					</label>
					<span
						v-if="getTerritoriesError"
						class="block mt-5 red text-center b col-span-2"
						>{{ getTerritoriesError }}</span
					>
					<span
						v-if="deleteError"
						class="block mt-5 red text-center b col-span-2"
						>{{ deleteError }}</span
					>
					<span
						v-if="createError"
						class="block mt-5 red text-center b col-span-2"
						>{{ createError }}</span
					>
					<span
						v-if="updateError"
						class="block mt-5 red text-center b col-span-2"
						>{{ updateError }}</span
					>
					<div
						class="col-span-2 my-6 flex flex-col items-center md:flex-row justify-evenly"
					>
						<SecondaryButton
							v-if="action == 'Actualizar'"
							@click.native="remove(territory)"
							text="Eliminar"
							buttonColor="bg-red"
							class="flex-none mb-3 md:mb-0 md:mr-2"
						/>
						<SecondaryButton
							@click.native="[toggleForm(), clear()]"
							v-else
							text="Cancelar"
							buttonColor="bg-red"
							class="flex-none mb-3 md:mb-0 md:mr-2"
						/>
						<SecondaryButton
							:text="action"
							@click.native="submit"
							class="flex-none"
						/>
					</div>
				</form>
			</div>
		</div>
		<main-form
			v-on:toggleForm="toggleForm"
			:action="action"
			text="Territorios"
			textBold="Red Única"
		></main-form>
		<table
			v-if="!isLoading && territories.length > 0"
			id="no-more-tables"
			class="block lg:table table-auto w-full"
		>
			<thead class="block lg:table-header-group elektra-bg-red">
				<tr class="block lg:table-row border border-gray">
					<th
						class="block text-white lg:table-cell b border-r border-gray py-3 px-1"
						v-for="(field, key) in territory"
						:key="key"
						v-show="key != 'id'"
						v-capitalizeWords=""
					>
						{{ field.name }}
					</th>
					<th
						class="block lg:table-cell b text-white border-r py-3 px-1"
					>
						Acciones
					</th>
				</tr>
			</thead>
			<tbody class="block md:flex flex-wrap lg:table-row-group">
				<tr
					class="block odd:bg-gray-light hover:bg-orange lg:table-row border-t border-b border-gray box-border"
					:class="
						territories.length > 1
							? 'w-full md:w-1/2 lg:w-full'
							: 'w-full'
					"
					v-for="(t, i) in territories"
					:key="i"
				>
					<td
						class="block before:text-center before:justify-center first:border-l lg:table-cell text-center border-r border-gray-light py-3 px-1"
						:class="{ 'green b': key == 'id' }"
						v-for="(field, key) in territory"
						:key="key"
						v-show="key != 'id'"
						v-capitalizeWords="t[key]"
					></td>
					<td
						class="flex justify-evenly px-1 items-center py-3 icons lg:table-cell"
					>
						<div class="w-full flex justify-evenly items-center">
							<span
								class="title-hint"
								aria-label="Da clic aquí para editar este Territorio"
							>
								<font-awesome-icon
									@click="update(t)"
									class="green hover:text-white text-3xl md:text-2xl cursor-pointer"
									icon="fa-solid fa-pen-to-square"
								/>
							</span>
							<span
								class="title-hint"
								aria-label="Da clic aquí para eliminar este Territorio"
							>
								<font-awesome-icon
									@click="remove(t)"
									class="red hover:text-white text-3xl md:text-2xl cursor-pointer"
									icon="fa-solid fa-trash-can"
								/>
							</span>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
		<span v-else-if="listed" class="block my-3 blue text-center b"
			>No se encontraron territorios</span
		>
	</div>
</template>

<style lang="scss" scoped>
/*
	Label the data
	*/

@media (max-width: 1023px) {
	#no-more-tables td:nth-child(2):before {
		content: "Nombre";
	}
}
</style>
